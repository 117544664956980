export const GET_HOME_PROMOCION = "[HOME PROMOCION] GET PROMOCION";
export const GET_HOME_PROMOCION_SUCCESS =
    "[HOME PROMOCION] GET PROMOCION SUCCESS";
export const GET_HOME_PROMOCION_ERROR = "[HOME PROMOCION] GET PROMOCION ERROR";

export const getHomePromocion = () => ({
    type: GET_HOME_PROMOCION,
});
export const getHomePromocionSuccess = (payload) => ({
    type: GET_HOME_PROMOCION_SUCCESS,
    payload,
});
export const getHomePromocionError = (payload) => ({
    type: GET_HOME_PROMOCION_ERROR,
    payload,
});
