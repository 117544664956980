import { lazy, Suspense } from "react";
import { Router, Redirect } from "@reach/router";
// import WebLayout from "layout/WebLayout";
import rootSagaHome from "./home/store/saga";

import initStore from "redux/store";
const store = initStore();

// import rootSagaNosotros from "./about/store/saga";

const WebLayout = lazy(() =>
  import(/* webpackChunkName: "view-web-web-layout-page" */ "layout/WebLayout")
);
const Home = lazy(() =>
  import("./home/store/reducers").then((module) => {
    store.injectReducer("HomeApp", module.default);
    store.injectSaga("HomeApp", rootSagaHome);
    return import(/* webpackChunkName: "view-web-HomePincipal" */ "./home");
  })
);
// const Home = lazy(() =>
//     import(/* webpackChunkName: "view-web-home-page" */ "./home")
// );
const SamySpa = lazy(() =>
  import(/* webpackChunkName: "view-web-samyspa-page" */ "./samyspa")
);
const Servicios = lazy(() =>
  import(/* webpackChunkName: "view-web-servicios-page" */ "./servicios")
);
const Contacto = lazy(() =>
  import(/* webpackChunkName: "view-web-contacto-page" */ "./contacto")
);
const PoliticaCookies = lazy(() =>
  import(
    /* webpackChunkName: "view-web-politica-cookies-page" */ "./legal/politica_cookies"
  )
);
const AvisoLegal = lazy(() =>
  import(
    /* webpackChunkName: "view-web-aviso-legal-page" */ "./legal/aviso_legal"
  )
);
const PoliticaPrivacidad = lazy(() =>
  import(
    /* webpackChunkName: "view-web-politica-privacidad-page" */ "./legal/politica_privacidad"
  )
);

// const About = lazy(() =>
//     import("./about/store/reducers").then((module) => {
//         // store.injectReducer("aboutApp", module.default);
//         // store.injectSaga("aboutApp", rootSagaNosotros);
//         return import(/* webpackChunkName: "webAbout" */ "./about");
//     })
// );

const BlankPage = lazy(() =>
  import(/* webpackChunkName: "viwes-web-blank-page" */ "./blank-page")
);

const Web = () => {
  return (
    <Suspense
      fallback={
        <div>
          <div className="loading" />
        </div>
      }
    >
      <WebLayout>
        <Router>
          <Redirect from="/" to="home" noThrow />
          <BlankPage default />
          <Home path="home/*" />
          <SamySpa path="samyspa/*" />
          <Servicios path="servicios/*" />
          <Contacto path="contacto/*" />
          <PoliticaCookies path="politica_cookies/*" />
          <AvisoLegal path="aviso_legal/*" />
          <PoliticaPrivacidad path="politica_privacidad/*" />
        </Router>
      </WebLayout>
    </Suspense>
  );
};
export default Web;
