import { call, put, takeEvery, fork } from "redux-saga/effects";
import { instance_public } from "helpers/Instance";

import {
    GET_HOME_PROMOCION,
    getHomePromocionSuccess,
    getHomePromocionError,
} from "../actions/promocion-actions";

const tabla = "promocion";

export function* watchGetHomePromocion() {
    yield takeEvery(GET_HOME_PROMOCION, GetHomePromocion);
}

function* GetHomePromocion() {
    try {
        const { data } = yield call(GetHomePromocionAsync);
        if (data?.success) yield put(getHomePromocionSuccess(data.data));
        else yield put(getHomePromocionError(data.message));
    } catch (error) {
        yield put(getHomePromocionError("Error :("));
        return;
    }
}

const GetHomePromocionAsync = () => instance_public.get(`${tabla}`);

const sagas = [fork(watchGetHomePromocion)];

export default sagas;
